import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../../Components/SEO';
import Breadcrumb from '../../Components/Breadcrumb';

const Topics = () => {
    return (
    <div>
         <SEO
            title='Grammar Exercises - English Exam Exercises'
            description='A big list of English grammar exercises to practice for exams'
            name='English Exam Exercises'
            type='article' />
            <Breadcrumb />
            <div>
        <h3 className="mt-2">Topics</h3>
      <p>Here is a collection of exercises organized by topic to help you synthesize the language.</p>
      </div>
            <div className='grammar-container'>
            <div className="exercises-container div1">
        <ul className="all-exercises-list">
        <li><p className="category-title" href="/reading-exercises">Money and Finances</p>
                <ul>
                    <li><Link to="/topics/newyearsresolution">New Year's Resolutions</Link></li>
                    <li><Link to="/topics/habits">Habits</Link></li>
                    <li><Link to="/topics/chatplatforms">Chat Platforms</Link></li>
                    <li><Link to="/topics/automation">Automation</Link></li>
                    <li><Link to="/topics/quality">Quality</Link></li>
                    <li><Link to="/topics/choice">Choice</Link></li>
                    <li><Link to="/topics/pets">Pets</Link></li>
                </ul>
            </li>
        </ul>
        </div>


            <figure data-title="Topics">
                                        <figcaption>
                                                <p>Choose your topic to find assorted related exercises. </p>
                                        <ul>
                                        <li><Link to="/#"><span class="label label-a1">A1</span> Environment</Link></li>
                                        <li><Link to="/#"><span class="label label-a2">A2</span> Education</Link></li>
                                                <li><Link to="/#"><span class="label label-b1">B1</span> Travel and Holidays</Link></li>
                                                <li><Link to="/#"><span class="label label-b2">B2</span> Money</Link></li>
                                                <li><Link to="/#"><span class="label label-c1">C1</span> Family and Friends</Link></li>
                                        </ul>
                                        </figcaption>
                                </figure>
          
                    
                    <div className='lead-container'>
                    <div className="homebodytext">
                                <h2><strong>Topics</strong></h2>
                                <p>Practice exercises oraganized around typical topics found on exams. </p>
                            </div>
                        
                        </div>
                
                </div>
    </div>
    )
}
 
export default Topics;

