import React from 'react';


const FutureExplanation = () => {

    return (
        <div>

<div className="grammar-display-grid">
                            <div className="">
                        <p>going to do - plans (informal)</p>
                        <p>about to do - will happen very soon and no time phrase can be added </p>
                        <p>thinking of doing</p>
                        <p>plan to do</p>
                        <p>planning to do</p>
                        <p>plan on doing</p>
                        <p>planning on doing</p>


                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default FutureExplanation;