import React from 'react';


const IndirectSpeechExplanation = () => {

    return (
        <div>

<div className="grammar-display-grid">
                            <div className="">
                            <h2>QW + do you think + subj + verb form</h2>
                            <h2>Do you think + subj + verb form</h2>

                                <p><strong>Examples:</strong></p>
                                <p>Do you think John is coming to the meeting?</p>
                                <p>Where do you think John is?</p>

                                <h2>Do you know + if + subj + verb form</h2>
                                <h2>Do you know + QW + subj + verb form</h2>

                                <p><strong>Common Phrases:</strong></p>
                                <p>Do you know</p>
                                <p>Could you tell me</p>
                                <p>I was wondering</p>
                                <p>Would you mind telling me</p>
                                <p>I’d like to know</p>
                                <p>Do you happen to know</p>
                                <p>Do you remember</p>
                                <p>Would you happen to know</p>
                                <p>Do you have any idea</p>


                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default IndirectSpeechExplanation;