import React, { useState } from 'react';
import questions from './Data/questions.json';
import '../../CSS/leveltest.css'
import ReadingMenu from '../../Components/Menus/ReadingMenu';
import Sidebar from '../../Components/Sidebar';
import leveltest from '../../Images/levels-header-1280x470.png';
import SEO from '../../Components/SEO';

import Breadcrumb from '../../Components/Breadcrumb';

const EnglishLevelTest = () => {
  const [hasStarted, setHasStarted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [result, setResult] = useState(null);

  const startTest = () => {
    setHasStarted(true); 
  };
  
  const handleAnswer = (selectedOption) => {
    if (selectedOption === questions[currentQuestionIndex].correctAnswer) {
      setScore(score + 1);
    }

    const nextQuestionIndex = currentQuestionIndex + 1;
    if (nextQuestionIndex < questions.length) {
      setCurrentQuestionIndex(nextQuestionIndex);
    } else {
      determineLevel();
    }
  };	 

  const determineLevel = () => {
    if (score <= 1) {
      setResult('Elementary');
    } else if (score <= 2) {
      setResult('Pre-intermediate');
    } else if (score <= 3) {
      setResult('Intermediate');
    } else if (score <= 4) {
      setResult('Upper-intermediate');
    } else {
      setResult('Advanced');
    }
  };

  if (!hasStarted) {
    return (
      <div>

      <SEO
  title='Vocabulary Quizzes - English Exam Exercises'
  description='A collection of flashcards to strengthen your vocabulary'
  name='English Exam Exercises'
  type='article'
/>
<Breadcrumb />        
      
    <div className='home-container'>
          <div className='homebodytext'>
                  <img src={leveltest} alt="English Exam Exercises" />
              <div className="introtext">
              
              <h3 className="mt-2">Level test</h3>
            <p>Here is a collection of exercises organized by topic to help you synthesize the language.</p>
            
            </div>
                  <div className="leveltest-container">
                  
                    <h1 className="leveltest-header">English Level Test</h1>
                    <button onClick={startTest} className="start-button">Start Test</button>
                  </div>
                 
            </div>
        
        <div> 
        <ReadingMenu />
        <Sidebar /> 
        </div>
    
    </div>
      </div>
    );
  }
  

  if (result) {
    return (
      <div>
            <div className='home-container'>
          <div className='homebodytext'>
                  <img src={leveltest} alt="English Exam Exercises" />
      <div className="introtext">
      <h3 className="mt-2">Level test</h3>
    <p>Here is a collection of exercises organized by topic to help you synthesize the language.</p>
    </div>
      <div className="leveltest-container div1">
     
        <h1 className="leveltest-header">English Level Test</h1>
        <div className="result">Your level is: {result}</div>
        <button onClick={() => window.location.reload()} className="restart-button">Restart Test</button>        
      
      </div>
      </div>
	   
     <div>
      <ReadingMenu /> 
      <Sidebar /> 
     </div>
      
      </div>
      </div>
  );
  }

  return ( 
    <div>
    
    
    <div className='home-container'>
          
          <div className='homebodytext'>
                  <img src={leveltest} alt="English Exam Exercises" />
      <div className="introtext">
      <h3 className="mt-2">Level test</h3>
    <p>Here is a level test to determine your language level. The test is 30 questions.</p>
    </div> 
  
 
    <div className="leveltest-container div1">
      <h1 className="leveltest-header">English Level Test</h1>
      <div className="question-counter">
        Question {currentQuestionIndex + 1} of {questions.length}
      </div>
      <div className="questionbox">
        <p className="question">
          {currentQuestionIndex + 1}. {questions[currentQuestionIndex].question}
        </p>
        <div className="options">
          {questions[currentQuestionIndex].options.map((option) => (
            <button key={option} onClick={() => handleAnswer(option)}>
              {option}
            </button>
          ))}
          </div>
        </div>
      </div>

      </div>
      
      

      <div className="div2">
        <ReadingMenu />
         <Sidebar /> 
         </div>
      
      
      </div>
      
      </div>
	  
	  
    
  );
};

export default EnglishLevelTest;
