import React from 'react';


const ComparisonExplanation = () => {

    return (
        <div>

                        <div>
                            
                            <div className="agendagrammar">
                                                        
                            <h2>Comparisons</h2>
*********************************************************

<ul>
  <li>
    <strong>to be big, bigger than, the biggest</strong><br></br><br></br>
    <strong>to be important, more important than, the most important</strong><br></br><br></br>
    <em>Example:</em> This is bigger than that.<br></br><br></br>
  </li>
*********************************************************
  <li>
    <strong>to be as [adjective] as</strong><br></br>
    <strong>to be not as [adjective] as</strong><br></br><br></br>
    <em>Example:</em> This is as big as that.<br></br><br></br>
  </li>
*********************************************************
  <li>
    <strong>to be different from + noun</strong><br></br>
    <strong>to be similar to + noun</strong><br></br>
    <strong>to be the same as + noun</strong><br></br><br></br>
    <em>Example:</em> This is different from that.<br></br><br></br>
  </li>
</ul>

<h4>Showing Big Differences</h4>
<ul>
  <li>
    <strong>to be much / so much / a lot / even / way / far + bigger + than</strong><br></br><br></br>
    <strong>to be nowhere near as + big + as</strong><br></br><br></br>
    <strong>to be not nearly as + big + as</strong><br></br><br></br>
    <em>Example:</em> This is nowhere near as big as that.<br></br><br></br>
  </li>
</ul>

<h4>Showing Small Differences</h4>
<ul>
  <li>
    <strong>to be slightly / a little / a bit / a little bit / not much + bigger than</strong><br></br><br></br>
    <strong>to be almost / nearly / not quite / roughly / more or less / about + as big as</strong><br></br><br></br>
    <strong>to be almost / nearly / not quite / roughly / more or less / about + the same as</strong><br></br><br></br>
    <strong>to be very / really + similar to</strong><br></br><br></br>
    <em>Example:</em> This is slightly bigger than that.<br></br><br></br>
  </li>
</ul>

<h4>Showing There Is No Difference</h4>
<ul>
  <li>
    <strong>to be exactly the same [characteristic: size, weight, etc.] as</strong><br></br><br></br>
    <strong>to be just as big as</strong><br></br><br></br>
    <em>Example:</em> This is exactly the same size as that.<br></br><br></br>
  </li>
</ul>



                </div>
               </div>
        </div>
);
};

export default ComparisonExplanation;