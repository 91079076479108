import React, { useState, useEffect } from 'react';
import SEO from '../../Components/SEO';
import Breadcrumbs from '../../Components/Breadcrumb';
import GrammarMenu from '../../Components/Menus/GrammarMenu';
import Sidebar from '../../Components/Sidebar';

// Import the JSON data
const reportedspeechData = require('./Data/reportedspeech.json');

const ReportedSpeech = () => {
  // State for storing user answers, current index, and evaluation
  const [answers, setAnswers] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to generate new sentences based on currentIndex
  const generateNewSentences = () => {
    const startIndex = currentIndex;
    const endIndex = Math.min(startIndex + 10, reportedspeechData.length);
    return reportedspeechData.slice(startIndex, endIndex);
  };

  // Function to handle input change
  const handleChange = (index, value) => {
    setAnswers(prevAnswers => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentIndex + index] = value;
      return updatedAnswers;
    });
  };

  // Function to handle form submission and evaluate answers
  const handleSubmit = (e) => {
    e.preventDefault();
    const slicedData = reportedspeechData.slice(currentIndex, currentIndex + 10);
    const updatedEvaluation = slicedData.map((sentenceObj, index) => {
      const inputValue = answers[currentIndex + index];
      const correctAnswer = sentenceObj.answer;
      return inputValue && inputValue.trim().toLowerCase() === correctAnswer.trim().toLowerCase()
        ? 'correct'
        : 'incorrect';
    });
    setEvaluation(updatedEvaluation);
  };

  // Function to handle moving to the next set of sentences
  const handleNextExercise = () => {
    const nextIndex = Math.min(currentIndex + 10, reportedspeechData.length);
    setCurrentIndex(nextIndex >= reportedspeechData.length ? 0 : nextIndex);
    setAnswers(Array(10).fill(null));
    setEvaluation([]);
  };

   // Function to handle moving to the previous set of sentences
   const handlePreviousExercise = () => {
    const previousIndex = Math.max(currentIndex - 10, 0);
    setCurrentIndex(previousIndex);
    setAnswers(Array(10).fill(null));
    setEvaluation([]);
  };

  // Reset answers when the currentIndex changes
  useEffect(() => {
    setAnswers(Array(10).fill(null));
  }, [currentIndex]);

  return (
    <div>
      <SEO title="Grammar Exercises - Reported Speech" description="Practice grammar exercises for reported speech" name="English Exam Exercises" type="article" />
      <Breadcrumbs />

      <div className="home-container">
        <div className="homebodytext div1">
          <div className="introtext">
            <h3 className="mt-2">Reported Speech</h3>
          </div>
          <div className="page-description">
            Here is a collection of grammar exercises to practice for exams. Each set includes 10 sentences.
          </div>

          <div className="exercises-container">
            <div className="buttonctrl">
            <button type="button" onClick={handlePreviousExercise}><i className="fas fa-arrow-left"></i> Previous Exercise</button>
              <button type="button" onClick={handleNextExercise}><i className="fas fa-arrow-right"></i> Next Exercise</button>
            </div>
            <form className="exercises-form" onSubmit={handleSubmit}>
              {generateNewSentences().map((sentenceObj, index) => (
                <div key={index}>
                  <div className="sentence-container">
                    <span className="circle">{index + 1}</span>
                    <div className="sentence-text">{sentenceObj.text}</div>
                    <input
                      type="text"
                      value={answers[currentIndex + index] || ''}
                      onChange={(e) => handleChange(index, e.target.value)}
                      placeholder={sentenceObj.prompt} // Use prompt as placeholder
                    />
                    <button className='checkbtn' type="submit">Check</button>	
                    <div>
                      {evaluation[index] === 'correct' ? ' ✔️' : evaluation[index] === 'incorrect' ? ' ❌' : ''}
                    </div>
                  </div>
                </div>
              ))}
            </form>
          </div>
        </div>

        <div className="gmenu div2">
          <GrammarMenu />
        </div>

        <div className="div3">
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default ReportedSpeech;
