import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select, { components } from 'react-select';
import sentencesData from '../../Mainpages/Grammar-Exercises/Data/simplepresent.json';
import PageCounter from '../../Utils/pagecounter';

const Simpleprescomp = () => {
  const [answers, setAnswers] = useState(Array(5).fill(''));
  const [evaluation, setEvaluation] = useState(Array(5).fill(''));
  const [displayedSentences, setDisplayedSentences] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedTopic, setSelectedTopic] = useState(null);

  const uniqueTopics = useMemo(() => {
    const topics = sentencesData.map((sentence) => sentence.topic);
    return Array.from(new Set(topics)).map((topic) => ({ label: topic, value: topic }));
  }, []);
    const filteredSentences = useMemo(() => {
    return selectedTopic
      ? sentencesData.filter((sentence) => sentence.topic === selectedTopic)
      : sentencesData;
  }, [selectedTopic]);

  // Calculate total number of pages based on filtered sentences
  const totalPages = Math.ceil(filteredSentences.length / 5);
  // Calculate current page
  const currentPage = Math.floor(currentIndex / 5) + 1;

  const [showAnswers, setShowAnswers] = useState(false);

  const toggleShowAnswers = () => {
    setShowAnswers((prevState) => !prevState);
  };

  // Generate new sentences based on current index and selected topic
  const generateNewSentences = useCallback(() => {
    const newSentences = filteredSentences.slice(currentIndex, currentIndex + 5);
    setDisplayedSentences(newSentences);
    setAnswers(Array(newSentences.length).fill(''));
    setEvaluation(Array(newSentences.length).fill(''));
  }, [currentIndex, filteredSentences]);

  useEffect(() => {
    generateNewSentences();
  }, [currentIndex, filteredSentences, generateNewSentences]);

  const handleChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedEvaluation = displayedSentences.map((sentence, index) => {
      const correctAnswer = sentence.answer;
      const userAnswer = answers[index]?.trim();

      if (!userAnswer) return '';

      return userAnswer.toLowerCase() === correctAnswer?.trim().toLowerCase()
        ? 'correct'
        : 'incorrect';
    });
    setEvaluation(updatedEvaluation);
  };

  const handleReset = () => {
    setAnswers(Array(displayedSentences.length).fill(''));
    setEvaluation(Array(displayedSentences.length).fill(''));
  };

  const handleNextExercise = () => {
    const nextIndex = (currentIndex + 5) % filteredSentences.length;
    setCurrentIndex(nextIndex);
  };

  const handlePreviousExercise = () => {
    const previousIndex = (currentIndex - 5 + filteredSentences.length) % filteredSentences.length;
    setCurrentIndex(previousIndex);
  };

  const CustomInput = (props) => {
    return <components.Input {...props} readOnly />;
  };

  return (
    <div>
      <div className="grammar-comp-container">
        <div className="introtext">
          <div className="grammartitle">
            <span className="label label-a2">A2</span>
            <h3 className="mt-2">Simple Present</h3>
          </div>

          <div className="exercises-container">

            <div className="exercisectrl">
              <PageCounter currentPage={currentPage} totalPages={totalPages} />
              
              <button type="button" className="checkbtn" onClick={handlePreviousExercise}><i className="fas fa-arrow-left"></i> Prev Set 
              </button>
              <button type="button" className="checkbtn" onClick={handleNextExercise}>
              Next Set <i className="fas fa-arrow-right"></i>
              </button>
              <button 
                    type="button" 
                    className="checkbtn" 
                    onClick={toggleShowAnswers}
                    >
                    {showAnswers ? 'Hide Answers' : 'Show Answers'}
                    </button>
              <div className="filter-container">
                <Select
                  value={selectedTopic ? { label: selectedTopic, value: selectedTopic } : null}
                  onChange={(option) => setSelectedTopic(option?.value || null)}
                  options={[{ label: 'All Topics', value: null }, ...uniqueTopics]} // Added unique topics
                  placeholder="Sentence topic"
                  isClearable
                  components={{ Input: CustomInput }}
                />
              </div>
            </div>
            <p className="descriptionbox">Complete the sentences with the correct form of the simple present tense.</p>
            <form className="exercises-form" onSubmit={handleSubmit}>
            {displayedSentences.map((sentence, index) => (
  <div key={index} className="sentence-container">
    <span className="circle">{index + 1}</span>
    {sentence.text.split('___').map((part, partIndex) => (
      <React.Fragment key={partIndex}>
        {partIndex > 0 && (
          <input
            type="text"
            value={showAnswers ? sentence.answer : answers[index] || ''}
            onChange={(e) => handleChange(index, e.target.value)}
            readOnly={showAnswers} // Make inputs readonly if answers are shown
          />
        )}
        {part}
      </React.Fragment>
    ))}
    {evaluation[index] === 'correct' ? ' ✔️' : evaluation[index] === 'incorrect' ? ' ❌' : ''}
  </div>
))}
              <div className="exercisectrl">
              <button 
                    type="button" 
                    className="checkbtn" 
                    onClick={toggleShowAnswers}
                    >
                    {showAnswers ? 'Hide Answers' : 'Show Answers'}
                    </button>
                <button type="submit" className="checkbtn">Check</button>
                <button type="submit" className="checkbtn" onClick={handleReset}>Reset</button>
                <button type="button" className="checkbtn" onClick={handlePreviousExercise}>
                <i className="fas fa-arrow-left"></i> Prev Set 
                </button>
                <button type="button" className="checkbtn" onClick={handleNextExercise}>
                Next Set <i className="fas fa-arrow-right"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="top-margin"></div>
      </div>
    </div>
  );
};

export default Simpleprescomp;
