import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select';
import sentencesData from '../../Mainpages/Vocabulary-Exercises/Data/idioms.json';
import PageCounter from '../../Utils/pagecounter';

const Idiomsexercise = ({ topic }) => {
  const [answers, setAnswers] = useState(Array(10).fill(null));
  const [evaluation, setEvaluation] = useState(Array(10).fill(''));
  const [displayedSentences, setDisplayedSentences] = useState([]);
  const [options, setOptions] = useState([]);
  const [sentencesWithOptions, setSentencesWithOptions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedTopic, setSelectedTopic] = useState(topic || null); 

  const [isGridVisible, setIsGridVisible] = useState(false);
  
  const initialSentencesWithOptions = useMemo(() => sentencesData, []);

  // Utility to shuffle an array
  const shuffleArray = (array) => {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  };

  const generateNewSentences = useCallback(() => {
    const filteredSentences = selectedTopic
      ? initialSentencesWithOptions.filter(sentence => sentence.topic === selectedTopic)
      : initialSentencesWithOptions;
  
    const newSentencesWithOptions = filteredSentences.slice(currentIndex, currentIndex + 10);
    const displayedSentences = newSentencesWithOptions.map(sentence => sentence.sentence);
    const options = newSentencesWithOptions.map(sentence => {
      const shuffledOptions = shuffleArray(sentence.options);
      return {
        options: shuffledOptions.map(option => ({ label: option, value: option })),
        correctAnswer: sentence.options[sentence.correctIndex],
        correctIndex: shuffledOptions.indexOf(sentence.options[sentence.correctIndex])
      };
    });
  
    setSentencesWithOptions(newSentencesWithOptions);
    setDisplayedSentences(displayedSentences);
    setOptions(options);
    setAnswers(Array(10).fill(null));
    setEvaluation(Array(10).fill(''));
  }, [currentIndex, initialSentencesWithOptions, selectedTopic]);

  const uniqueTopics = useMemo(() => {
    const topics = initialSentencesWithOptions.map(sentence => sentence.topic);
    return Array.from(new Set(topics)).map(topic => ({ label: topic, value: topic }));
  }, [initialSentencesWithOptions]);

  useEffect(() => {
    generateNewSentences();
  }, [currentIndex, selectedTopic, generateNewSentences]);

  const handleChange = (index, selectedOption) => {
    if (selectedOption !== null) {
      const updatedAnswers = [...answers];
      updatedAnswers[index] = selectedOption;
      setAnswers(updatedAnswers);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedEvaluation = answers.map((selectedOption, index) => {
      if (selectedOption === null || selectedOption.value === null) {
        console.log("No option selected for index:", index);
        return '';
      }
      const { correctAnswer } = options[index];
      if (selectedOption.value === correctAnswer) {
        console.log("Answer for index", index, "is correct");
        return 'correct';
      } else {
        console.log("Answer for index", index, "is incorrect");
        return 'incorrect';
      }
    });
    console.log("Updated evaluation:", updatedEvaluation);
    setEvaluation(updatedEvaluation);
  };

  const handleReset = () => {
    setAnswers(Array(displayedSentences.length).fill(''));
    setEvaluation(Array(displayedSentences.length).fill(''));
  };

  const handleNextExercise = () => {
    const filteredSentences = selectedTopic
      ? initialSentencesWithOptions.filter(sentence => sentence.topic === selectedTopic)
      : initialSentencesWithOptions;
    const totalSentences = filteredSentences.length;
  
    // Calculate the next starting index
    let nextIndex = currentIndex + 10;
  
    if (nextIndex >= totalSentences) {
      // Wrap around if we've reached the end of the filtered sentences
      nextIndex = nextIndex % totalSentences;
    }
  
    setCurrentIndex(nextIndex);
  };
  
  const handlePreviousExercise = () => {
    const filteredSentences = selectedTopic
      ? initialSentencesWithOptions.filter(sentence => sentence.topic === selectedTopic)
      : initialSentencesWithOptions;
    const totalSentences = filteredSentences.length;
  
    // Calculate the previous starting index
    let previousIndex = currentIndex - 10;
  
    if (previousIndex < 0) {
      // Wrap around to the end if we've gone below zero
      previousIndex = totalSentences + previousIndex;
    }
  
    setCurrentIndex(previousIndex);
  };

  const toggleGridVisibility = () => {
    setIsGridVisible(!isGridVisible); 
  };

    // Calculate the total number of pages (units) based on the filtered sentences
    const filteredSentences = selectedTopic
    ? initialSentencesWithOptions.filter(sentence => sentence.topic === selectedTopic)
    : initialSentencesWithOptions;
  const totalPages = Math.ceil(filteredSentences.length / 10);
  const currentPage = Math.floor(currentIndex / 10) + 1;


  return (
    <div>
<div className="grammar-comp-container">
      <div>
 <div className="grammartitle">
 <span className="label label-c1">C1</span> 
        <h3 className="mt-2">Idioms and Phrasal Verbs Exercise</h3>
        </div>
        
        <div className="exercises-container">

        <div className="exercisectrl">
        <PageCounter currentPage={currentPage} totalPages={totalPages} />
              <button type="button" className="checkbtn" onClick={handlePreviousExercise}><i className="fas fa-arrow-left"></i> Prev Set 
              </button>
              <button type="button" className="checkbtn" onClick={handleNextExercise}>
              Next Set <i className="fas fa-arrow-right"></i>
              </button>
              <button type="submit" className="checkbtn" onClick={handleSubmit}>Check</button>
              <button type="submit" className="checkbtn" onClick={handleReset}>Reset</button>
        <div className="filter-container">
        <Select
          value={selectedTopic ? { label: selectedTopic, value: selectedTopic } : null}
          onChange={(option) => setSelectedTopic(option ? option.value : null)}
          options={[{ label: "All Topics", value: null }, ...uniqueTopics]}
          placeholder="Filter by topic"
          isClearable
        />
      </div>
      </div>
      <p className="descriptionbox">Complete the sentences with the correct form of the word.</p> 

          <form className="exercises-form" onSubmit={handleSubmit}>
            {displayedSentences.map((sentence, index) => {
              const parts = sentence.split("___");
              return (
                <div key={index}>
                  <div className="sentence-container">
                    <span className="circle">{index + 1}</span>
                    {parts[0]}
                    <Select
                      value={answers[index]}
                      onChange={(selectedOption) => handleChange(index, selectedOption)}
                      options={options[index].options}
                      isSearchable={false}
                      styles={{
                        control: (base) => ({
                          ...base,
                          width: '150px',
                          minWidth: '150px',
                          height: '30px',
                          fontSize: '14px',
                          padding: '0',
                          display: 'flex',
                          alignItems: 'center',
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          padding: '0 4px',
                          display: 'flex',
                          alignItems: 'center',
                        }),
                        singleValue: (base) => ({
                          ...base,
                          marginTop: '5px',
                          fontSize: '16px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          fontSize: '14px',
                          color: '#aaa',
                          marginTop: '8px',
                        }),
                        indicatorsContainer: (base) => ({
                          ...base,
                          height: '30px',
                          alignItems: 'center',
                        }),
                        dropdownIndicator: (base) => ({
                          ...base,
                          padding: '4px',
                        }),
                        indicatorSeparator: (base) => ({
                          ...base,
                          height: '100%',
                          margin: '3px 4px',
                        }),
                        menu: (base) => ({
                          ...base,
                          zIndex: 5,
                        }),
                      }}
                    />
                    {parts[1]}
                    {evaluation[index] === 'correct' ? ' ✔️' : evaluation[index] === 'incorrect' ? ' ❌' : ''}
                  </div>
                </div>
              );
            })}
            
            <div className="exercisectrl">
              <button type="submit" className="checkbtn">Check</button>
              <button type="submit" className="checkbtn" onClick={handleReset}>Reset</button>
              <button type="button" className="checkbtn" onClick={handlePreviousExercise}>
              Prev Set <i className="fas fa-arrow-left"></i>
              </button>
              <button type="button" className="checkbtn" onClick={handleNextExercise}>
              Next Set <i className="fas fa-arrow-right"></i>
              </button></div>
          </form>
        </div>
        </div>
        
      
       
      </div>
    </div>
  );
};

export default Idiomsexercise;
