import React, { useState, useEffect } from "react";

const RandomQuestionGenerator = ({ discussionquestions, topic }) => {
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(
    "Click the button to get a question!"
  );

  useEffect(() => {
    // Filter the questions based on the topic prop
    if (topic) {
      setFilteredQuestions(
        discussionquestions.filter((question) => question.topic === topic)
      );
    } else {
      setFilteredQuestions(discussionquestions);
    }
  }, [discussionquestions, topic]);

  const getRandomQuestion = () => {
    if (filteredQuestions.length === 0) {
      // Restart the list if all questions have been used
      setFilteredQuestions(
        topic
          ? discussionquestions.filter((question) => question.topic === topic)
          : discussionquestions
      );
      setCurrentQuestion("The list has restarted! Click again for a question.");
      return;
    }

    const randomIndex = Math.floor(Math.random() * filteredQuestions.length);
    const selectedQuestion = filteredQuestions[randomIndex];

    // Update the current question and remove it from the list
    setCurrentQuestion(selectedQuestion.text);
    setFilteredQuestions(filteredQuestions.filter((_, index) => index !== randomIndex));
  };

  return (
    <div className="qgenerator" style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Discussion Questions</h1>
      <div id="question" style={{ fontSize: "18px", margin: "20px 0" }}>
        {currentQuestion}
      </div>
      <button
        onClick={getRandomQuestion}
        style={{
          padding: "10px 20px",
          fontSize: "1em",
          cursor: "pointer",
        }}
      >
        Get a Question
      </button>
    </div>
  );
};

export default RandomQuestionGenerator;
