import React from 'react';
import Breadcrumbs from '../../Components/Breadcrumb';
import SEO from '../../Components/SEO';
import VocabularyMenu from '../../Components/Menus/VocabularyMenu';
import Sidebar from '../../Components/Sidebar';
import Idiomslist from '../../Components/VocabularyComps/idiomslist.js';
import Idiomsexercise from '../../Components/VocabularyExerciseComps/idiomsexercise.js';
import idiomsData from '../../Components/VocabularyComps/Data/idiomslist.json'; 

const Idioms = () => {
  return (
    <div>
      <SEO
        title='Idioms - Vocabulary Exercises - English Exam Exercises'
        description='A collection of texts to practice reading comprehension'
        name='English Exam Exercises'
        type='article'
      /> 

<Breadcrumbs />
      <div className="grammar-container">
        <div className="introtext">
          <Idiomslist idiomslist={idiomsData} />
          <Idiomsexercise />
        </div>

        <div className="top-margin">
          <VocabularyMenu />
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default Idioms;
