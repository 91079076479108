import React from 'react';


const AdjadvExplanation = () => {

    return (
        <div>

<div className="agenda-display-grid">
                            <div className="agendagrammar">
                                
                                <p><strong>Adverbs Modifying Adjectives</strong></p>

                                    <p>
                                    When an adverb modifies an adjective, it usually comes directly 
                                     <strong> before</strong> the adjective. This close position emphasizes the 
                                    connection between the adverb and the adjective it modifies.
                                    </p>
                                    <p><strong>Examples:</strong></p>
                                    <ul>
                                    <li>The project is <strong>extremely important</strong>.</li>
                                    <li>She is <strong>quite talented</strong>.</li>
                                    <li>They were <strong>perfectly satisfied</strong> with the results.</li>
                                    </ul>

                                    <p><strong>A lot - very:</strong></p>
                                    <ul>
                                    <li> extremely, incredibly, highly, remarkably (always positive), completely, absolutely, utterly (tends to be negative), vehemently (always negative, almost violent)</li>
                                    </ul>

                                    <p><strong>Medium amount - kind of:</strong></p>
                                    <ul>
                                    <li>quite, pretty, fairly, rather, kind of, moderately, almost</li>
                                    </ul>

                                    <p><strong>A little:</strong></p>
                                    <ul>
                                    <li>somewhat, slightly, a bit</li>
                                    </ul>

                                    <h3>Special Cases</h3>
                                    <ul>
                                    <li>
                                        Some adverbs like <strong>absolutely</strong> and <strong>completely</strong> are often used with 
                                        <em>non-gradable adjectives</em> (adjectives that already express an extreme or 
                                        absolute quality, such as "perfect," "unique," or "horrible"). 
                                    </li><br></br>
                                    <li>
                                        Adverbs like <strong>fairly</strong> or <strong>rather</strong> can sometimes convey subtle 
                                        opinions, such as approval, disapproval, or reservation.
                                    </li>
                                    </ul>


                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default AdjadvExplanation;