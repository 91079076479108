import React from 'react';


const CauseeffectExplanation = () => {

    return (
        <div>

        <div className="agenda-display-grid">
                <div className="agendagrammar">
                                
                               
                <h2>Cause and Effect Grammar</h2>
Cause:
<ul> 
        <li><strong>because</strong> - <em>because + subject + verb</em> (e.g., Because I was late, I missed the bus.)</li> 
        <li><strong>because of</strong> - <em>because of + noun</em> (e.g., The flight was delayed because of bad weather.)</li> 
        <li><strong>due to</strong> - <em>due to + noun</em> (e.g., The delay was due to traffic.)</li> 
        <li><strong>since</strong> - <em>since + subject + verb</em> (e.g., Since it rained, we stayed inside.)</li> 
        <li><strong>as</strong> - <em>as + subject + verb</em> (e.g., As I was tired, I went to bed early.)</li> 
        <li><strong>in light of (means "because of")</strong> - <em>in light of + noun/the fact that + subj + verb</em> (e.g., In light of the fact that Miklos is stuck in traffic, we decided to postpone the meeting.)</li> 
        <li><strong>thanks to</strong> - <em>thanks to + noun</em> (e.g., Thanks to her hard work, the project was a success.)</li> </ul>
Effect:
<ul> <li><strong>therefore (means "so", usually written, needs semi colon)</strong> - <em>subject + verb + therefore + subject + verb</em> (e.g., She didn't study; therefore, she failed the test.)</li><li><strong>therefore (means "so", more common spoken)</strong> - <em>subject + verb + therefore + verbing</em> (e.g., She didn't study therefore failing the test.)</li>
<li><strong>hence (means "which is the cause of")</strong> - <em>subject + verb + hence + noun</em> (e.g., Our server was down, hence the delay in responding.)</li> 
<li><strong>so</strong> - <em>subject + verb + so + subject + verb</em> (e.g., It rained, so we stayed inside.)</li> 
<li><strong>as a result of</strong> - <em>as a result of + noun/verbing</em> (e.g., My plane was late as a result of a snowstorm.)</li> </ul>



                    
                                    


                </div>
                                    
        </div>

        
        
        
        </div>
);
};

export default CauseeffectExplanation;