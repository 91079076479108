import React, { useState, useEffect } from 'react';
import idiomsData from '../VocabularyComps/Data/idiomslist.json';

const IdiomsList = ({ idiomslist, topic }) => {
  // Initialize state to display "all" or filter by the `topic` prop
  const [selectedTopic, setSelectedTopic] = useState('all');

  // Update state if the `topic` prop changes
  useEffect(() => {
    if (topic) {
      setSelectedTopic(topic);
    }
  }, [topic]);

  // Extract unique topics from the JSON data
  const topics = ['all', ...new Set(idiomslist.map((idiom) => idiom.topic))];

  // Filter idioms based on the selected topic
  const filteredIdioms = selectedTopic === 'all'
    ? idiomslist
    : idiomslist.filter((idiom) => idiom.topic === selectedTopic);

  return (
    <div>
      <div>Idioms</div>

      {/* Dropdown for selecting topic */}
      <div>
        <label htmlFor="topic-select">Filter by topic: </label>
        <select
          id="topic-select"
          onChange={(e) => setSelectedTopic(e.target.value)}
          value={selectedTopic}
        >
          {topics.map((topicOption, index) => (
            <option key={index} value={topicOption}>
              {topicOption.charAt(0).toUpperCase() + topicOption.slice(1)} {/* Capitalize the first letter */}
            </option>
          ))}
        </select>
      </div>

      <div className="grammar-display-grid">
        {filteredIdioms.map((idiom, index) => (
          <div key={index} className="idiom-item">
            <strong>{idiom.phrase}</strong>: {idiom.meaning}
            <p>Example: {idiom.example}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IdiomsList;
