import React from 'react';


const PastHabitsExplanation = () => {

    return (
        <div>

<div className="grammar-display-grid">
                            <div className="">
<h2><strong>Present Habits</strong></h2> 

<h5>1. <strong>Prone to doing/prone to something</strong></h5>
<p><strong>Prone to</strong> refers to a tendency or inclination to do something, usually something negative or undesirable.</p>
<p><strong>Structure:</strong> <em>Subject + be + prone to + noun/verb (ing)</em></p>
<ul>
  <li><strong>Example:</strong> She is prone to getting headaches if she doesn't sleep well.</li>
</ul>

<h5>2. <strong>Keep doing</strong></h5>
<p><strong>Keep doing</strong> expresses the idea of continuing to do something regularly or consistently over time. Can also be used to talk about irritating behavior.</p>
<p><strong>Structure:</strong> <em>Subject + keep + verb(ing)</em></p>
<ul>
  <li><strong>Example:</strong> He keeps going to the gym every morning.</li>
  <li><strong>Example:</strong> Our neighbors keep asking us for sugar.</li>
 
</ul>

<h5>3. <strong>Tend to do</strong></h5>
<p><strong>Tend to</strong> describes a habit or action that usually happens or is likely to happen in a given situation.</p>
<p><strong>Structure:</strong> <em>Subject + tend to + base verb</em></p>
<ul>
  <li><strong>Example:</strong> I tend to drink coffee in the morning.</li>
</ul>

<h5>4. <strong>Be in the habit of doing</strong></h5>
<p><strong>Be in the habit of</strong> indicates a regular or customary action that has become a routine.</p>
<p><strong>Structure:</strong> <em>Subject + be + in the habit of + verb(ing)</em></p>
<ul>
  <li><strong>Example:</strong> She is in the habit of checking her email every hour.</li>
</ul>

<h5>5. <strong>Always + do</strong></h5>
<p><strong>Always</strong> can be used to express a habit, especially when the action happens frequently or is a characteristic of the person.</p>
<p><strong>Structure:</strong> <em>Subject + always + verb</em></p>
<ul>
  <li><strong>Example:</strong> He always arrives early to meetings.</li>
</ul>

<h5>6. <strong>To be always + doing</strong></h5>
<p><strong>to be always verbing</strong> can be used to express an irritating habit.</p>
<p><strong>Structure:</strong> <em>Subject + always + verb</em></p>
<ul>
  <li><strong>Example:</strong> She's always kicking the back of my chair in class.</li>
</ul>

<h5>7. <strong>Be used to doing</strong></h5>
<p><strong>Be used to doing</strong> indicates that someone has adapted to a particular habit or behavior, making it a normal part of their routine.</p>
<p><strong>Structure:</strong> <em>Subject + be + used to + noun/verb(ing)</em></p>
<ul>
  <li><strong>Example:</strong> I am used to waking up early for work.</li>
</ul>

<p>These expressions help describe actions or behaviors that are habitual or typical in the present.</p>

<h2><strong>Past Habits</strong></h2> 

<h5>1. <strong>Used to do</strong></h5>
<p><strong>Used to</strong> is the most common way to express past habits or repeated actions that no longer happen.</p>
<p><strong>Structure:</strong> <em>Subject + used to + base form of the verb</em></p>
<ul>
  <li><strong>Example:</strong> I used to go for a run every morning.</li>
</ul>

<h5>2. <strong>Would do</strong> (for repeated actions)</h5>
<p><strong>Would</strong> can also be used to talk about repeated actions in the past, but it is less common for describing general past habits.</p>
<p><strong>Structure:</strong> <em>Subject + would + base form of the verb</em></p>
<ul>
  <li><strong>Example:</strong> When we were kids, we would play outside all day.</li>
</ul>

<h5>3. <strong>Simple Past</strong> (for specific habitual actions)</h5>
<p>Sometimes, the <strong>simple past</strong> can describe a past habit, especially when you focus on specific actions that were repeated in the past.</p>
<p><strong>Structure:</strong> <em>Subject + verb (past tense)</em></p>
<ul>
  <li><strong>Example:</strong> Every summer, we traveled to the beach.</li>
</ul>

<h5>Negative Forms</h5>
<p><strong>Used to</strong>: <em>Subject + didn’t + use to + base verb</em></p>
<ul>
  <li><strong>Example:</strong> I didn’t use to like coffee.</li>
</ul>




                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default PastHabitsExplanation;