import React, { useState, useEffect } from 'react';
import '../../CSS/grammar-menu.css'
import { Link } from 'react-router-dom';

const GrammarMenu = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 990);

   const toggleMenuVisibility = () => {
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 990);
      if (window.innerWidth > 990) {
        setMenuVisible(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="grammar-menu-container">
      <figure data-title="More Grammar Exercises"
          className="menu-toggle-button-grammar" 
          onClick={toggleMenuVisibility} 
          disabled={!isMobile}
        >
      <figcaption>
      <nav className={`grammar-navbar ${menuVisible || !isMobile ? 'visible' : ''}`}>
        <ul className="grammar-navbar-list">
          <li className="grammar-navbar-item">
            <ul className="grammar-submenu">
              <li><span className="label label-a1">A1</span><Link to="/grammar-exercises/to-be"> To Be - present</Link></li>
              <li><span className="label label-a1">A1</span><Link to="/grammar-exercises/to-be-past"> To Be - past</Link></li>
              <li><Link to="/grammar-exercises/simple-present"><span className="label label-a1">A1</span> Simple Present</Link></li>
              <li><Link to="/grammar-exercises/present-continuous"><span className="label label-a2">A2</span> Present Continuous</Link></li>
              <li><Link to="/grammar-exercises/simpcont"><span className="label label-a2">A2</span> Simple Present vs. Present Continuous</Link></li>
              <li><Link to="/grammar-exercises/simple-past"><span className="label label-a2">A2</span> Simple Past</Link></li>
              <li><Link to="/grammar-exercises/past-continuous"><span className="label label-a2">A2</span> Past Continuous</Link></li>
              <li><Link to="/grammar-exercises/future"><span className="label label-a2">A2</span> Future</Link></li>
              <li><Link to="/grammar-exercises/present-perfect"><span className="label label-b1">B1</span> Present Perfect</Link></li>
              <li><Link to="/grammar-exercises/perfect-past"><span className="label label-b1">B1</span> Present Perfect vs Past Simple</Link></li>
              <li><Link to="/grammar-exercises/first-conditional"><span className="label label-b2">B2</span> 1st Conditional</Link></li>
              <li><Link to="/grammar-exercises/second-conditional"><span className="label label-b2">B2</span> 2nd Conditional</Link></li>
              <li><Link to="/grammar-exercises/third-conditional"><span className="label label-c1">C1</span> 3rd Conditional</Link></li>
              <li><Link to="/grammar-exercises/passive"><span className="label label-c1">C1</span> Passive</Link></li>
              <li><Link to="/grammar-exercises/indirect-speech"><span className="label label-c1">C1</span> Indirect Speech</Link></li>
              <li><Link to="/grammar-exercises/sense-verbs"><span className="label label-c1">C1</span> Sense Verbs</Link></li>
              <li><Link to="/grammar-exercises/ed-ing"><span className="label label-c1">C1</span> Ed or Ing</Link></li>
              <li><Link to="/grammar-exercises/reported-speech"><span className="label label-c1">C1</span> Reported Speech</Link></li>
              <li><Link to="/grammar-exercises/modals-obligation"><span className="label label-c1">C1</span> Modals of Obligation</Link></li>
              
            </ul>
          </li>
        </ul>
      </nav></figcaption></figure>
    </div>
  );
};

export default GrammarMenu;
