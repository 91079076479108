import React, { useState, useEffect } from 'react';
import indirectspeechData from '../../Mainpages/Grammar-Exercises/Data/indirectspeech.json'



const IndirectSpeechExercise = () => {
  const [answers, setAnswers] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);


  const generateNewSentences = () => {
    const startIndex = currentIndex;
    const endIndex = Math.min(startIndex + 10, indirectspeechData.length);
    return indirectspeechData.slice(startIndex, endIndex);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const slicedData = indirectspeechData.slice(currentIndex, currentIndex + 10);
    const updatedEvaluation = slicedData.map((sentenceObj, index) => {
      const inputValue = answers[currentIndex + index];
      const correctAnswer = sentenceObj.answer;
      return inputValue && inputValue.trim().toLowerCase() === correctAnswer.trim().toLowerCase()
        ? 'correct'
        : 'incorrect';
    });
    setEvaluation(updatedEvaluation);
  };

  const handleNextExercise = () => {
    const nextIndex = Math.min(currentIndex + 10, indirectspeechData.length);
    setCurrentIndex(nextIndex >= indirectspeechData.length ? 0 : nextIndex);
    setAnswers(Array(10).fill(null));
    setEvaluation([]);
  };

  const handlePreviousExercise = () => {
    const previousIndex = Math.max(currentIndex - 10, 0);
    setCurrentIndex(previousIndex);
    setAnswers(Array(10).fill(null));
    setEvaluation([]);
  };

  const handleReset = () => {
    setAnswers(Array(generateNewSentences().length).fill(''));
    setEvaluation(Array(generateNewSentences().length).fill(''));
  };

  useEffect(() => {
    setAnswers(Array(10).fill(null));
  }, [currentIndex]);

  const handleChange = (index, value) => {
    setAnswers(prevAnswers => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentIndex + index] = value;
      return updatedAnswers;
    });
  };



  return (
    <div>

      <div className="home-container">
        <div className="homebodytext div1">
          <div className="introtext">
            <h3 className="mt-2">Indirect Speech (Exercise)</h3>
          </div>
         

          <div className="exercises-container">
            <div className="buttonctrl-bottom">
              <button type="submit" className="checkbtn" onClick={handleSubmit}><i className="fas fa-check"></i> Check</button>
              <button type="button" className="checkbtn" onClick={handlePreviousExercise}><i className="fas fa-arrow-left"></i> Previous Exercise</button>
              <button type="button" className="checkbtn" onClick={handleNextExercise}><i className="fas fa-arrow-right"></i> Next Exercise</button>
              <button type="button" className="checkbtn" onClick={handleReset}><i className="fas fa-undo"></i> Reset</button>
            </div>
           
            <form className="exercises-form" onSubmit={handleSubmit}>
              {generateNewSentences().map((sentenceObj, index) => (
                <div key={index}>
                  <div className="sentence-container">
                    <span className="circle">{index + 1}</span>
                    <div className="sentence-text">{sentenceObj.text}</div>
                    <input
                      type="text"
                      value={answers[currentIndex + index] || ''}
                      onChange={(e) => handleChange(index, e.target.value)}
                      placeholder={sentenceObj.prompt}
                    />
                    <div>
                      {evaluation[index] === 'correct' ? ' ✔️' : evaluation[index] === 'incorrect' ? ' ❌' : ''}
                    </div>
                  </div>
                </div>
              ))}
            </form>
            <div className="buttonctrl-bottom">
            <button type="button" className="checkbtn" onClick={handlePreviousExercise}><i className="fas fa-arrow-left"></i> Previous Exercise</button>
              <button type="button" className="checkbtn"  onClick={handleNextExercise}><i className="fas fa-arrow-right"></i> Next Exercise</button>
				</div>							   
          </div>
        </div>

        <div className="gmenu div2">
          
        </div>

        <div className="div3">
          
        </div>
      </div>
    </div>
  );
};

export default IndirectSpeechExercise;
