import React, { useState, useEffect } from 'react';
import SEO from '../../Components/SEO';
import Breadcrumbs from '../../Components/Breadcrumb';
import GrammarMenu from '../../Components/Menus/GrammarMenu';
import Sidebar from '../../Components/Sidebar';

const passiveData = require('./Data/passive.json');

const Passive = () => {
  const [answers, setAnswers] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isGridVisible, setIsGridVisible] = useState(false);

  const [grammarData] = useState([
    ["act", "action", "active", "actively"],
    ["decide", "decision", "decisive", "decisively"],
  ]);

  const generateNewSentences = () => {
    const startIndex = currentIndex;
    const endIndex = Math.min(startIndex + 10, passiveData.length);
    return passiveData.slice(startIndex, endIndex);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const slicedData = passiveData.slice(currentIndex, currentIndex + 10);
    const updatedEvaluation = slicedData.map((sentenceObj, index) => {
      const inputValue = answers[currentIndex + index];
      const correctAnswer = sentenceObj.answer;
      return inputValue && inputValue.trim().toLowerCase() === correctAnswer.trim().toLowerCase()
        ? 'correct'
        : 'incorrect';
    });
    setEvaluation(updatedEvaluation);
  };

  const handleNextExercise = () => {
    const nextIndex = Math.min(currentIndex + 10, passiveData.length);
    setCurrentIndex(nextIndex >= passiveData.length ? 0 : nextIndex);
    setAnswers(Array(10).fill(null));
    setEvaluation([]);
  };

  const handlePreviousExercise = () => {
    const previousIndex = Math.max(currentIndex - 10, 0);
    setCurrentIndex(previousIndex);
    setAnswers(Array(10).fill(null));
    setEvaluation([]);
  };

  const handleReset = () => {
    setAnswers(Array(generateNewSentences().length).fill(''));
    setEvaluation(Array(generateNewSentences().length).fill(''));
  };

  useEffect(() => {
    setAnswers(Array(10).fill(null));
  }, [currentIndex]);

  const handleChange = (index, value) => {
    setAnswers(prevAnswers => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentIndex + index] = value;
      return updatedAnswers;
    });
  };

  const toggleGridVisibility = () => {
    setIsGridVisible(!isGridVisible); 
  };

  return (
    <div>
      <SEO title="Grammar Exercises - Passive" description="Practice grammar exercises for the Passive tense" name="English Exam Exercises" type="article" />
     

      <div className="grammar-container">
          <div className="introtext">
            <Breadcrumbs />
             <div className="grammartitle">
             <span className="label label-c1">C1</span> 
            <h3 className="mt-2">Passive</h3></div>
          
          
          <div className="descriptionbox">
            <p>Grammar description</p>

            <div className="grammarctrl descriptionbox">
              <button type="button" className="grammarbtn" onClick={toggleGridVisibility}>
                {isGridVisible ? "Hide Grammar" : "Show Grammar"}
              </button>
            </div>

            {isGridVisible && (
              <div className="grammar-display-grid">
                {grammarData.map((row, index) => (
                  <div key={index} className="grammar-display-row">
                    {row.map((form, i) => (
                      <div key={i} className="grammar-display-item">
                        {form}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="exercises-container">
            <div className="buttonctrl-bottom">
              <button type="submit" className="checkbtn" onClick={handleSubmit}><i className="fas fa-check"></i> Check</button>
              <button type="button" className="checkbtn" onClick={handlePreviousExercise}><i className="fas fa-arrow-left"></i> Previous Exercise</button>
              <button type="button" className="checkbtn" onClick={handleNextExercise}><i className="fas fa-arrow-right"></i> Next Exercise</button>
              <button type="button" className="checkbtn" onClick={handleReset}><i className="fas fa-undo"></i> Reset</button>
            </div>
            <p className="descriptionbox">Complete the sentences with the correct form of the word.</p> 
            <form className="exercises-form" onSubmit={handleSubmit}>
              {generateNewSentences().map((sentenceObj, index) => (
                <div key={index}>
                  <div className="sentence-container">
                    <span className="circle">{index + 1}</span>
                    <div className="sentence-text">{sentenceObj.text}</div>
                    <input
                      type="text"
                      value={answers[currentIndex + index] || ''}
                      onChange={(e) => handleChange(index, e.target.value)}
                      placeholder={sentenceObj.prompt}
                    />
                  
                      {evaluation[index] === 'correct' ? ' ✔️' : evaluation[index] === 'incorrect' ? ' ❌' : ''}
                    
                  </div>
                </div>
              ))}
              <div className="buttonctrl-bottom">
            <button type="button" className="checkbtn" onClick={handlePreviousExercise}><i className="fas fa-arrow-left"></i> Previous Exercise</button>
              <button type="button" className="checkbtn"  onClick={handleNextExercise}><i className="fas fa-arrow-right"></i> Next Exercise</button>
				</div>	
            </form>
            						   
          </div>
      

        
        </div>

        <div className="top-margin"><GrammarMenu /><Sidebar /></div>
      </div>
    </div>
  );
};

export default Passive;
