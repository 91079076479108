import React from 'react';


const SoenoughExplanation = () => {

    return (
        <div>

<div className="agenda-display-grid">
                            <div className="agendagrammar">
                                
                            <h2><strong>So, such, enough, too</strong></h2>

                                        <p><strong>1. So</strong></p>
                                        <p><strong>Usage:</strong> Used to show a result or degree.</p>
                                        <p><strong>Examples:</strong></p>
                                        <ul>
                                        <li>He was so tired that he fell asleep immediately. (degree and result)</li>
                                        <li>It’s so cold! (emphasis on degree)</li>
                                        </ul>

                                        <p><strong>2. So that</strong></p>
                                        <p><strong>Usage:</strong> Used to express purpose or result.</p>
                                        <p><strong>Examples:</strong></p>
                                        <ul>
                                        <li>She left early so that she could catch the train. (purpose)</li>
                                        <li>I raised my voice so that everyone could hear me. (result)</li>
                                        </ul>

                                        <p><strong>3. Such</strong></p>
                                        <p><strong>Usage:</strong> Used to emphasize a quality, often followed by a noun phrase.</p>
                                        <p><strong>Examples:</strong></p>
                                        <ul>
                                        <li>It was such a beautiful day that we decided to go for a picnic.</li>
                                        <li>She’s such a kind person!</li>
                                        </ul>

                                        <p><strong>4. Such that</strong></p>
                                        <p><strong>Usage:</strong> Used to indicate a result or consequence.</p>
                                        <p><strong>Examples:</strong></p>
                                        <ul>
                                        <li>His performance was such that everyone gave him a standing ovation.</li>
                                        <li>The conditions were such that we had to cancel the event.</li>
                                        </ul>

                                        <p><strong>5. Too</strong></p>
                                        <p><strong>Usage:</strong> Indicates an excessive degree; often implies a negative result.</p>
                                        <p><strong>Examples:</strong></p>
                                        <ul>
                                        <li>It’s too hot to go outside.</li>
                                        <li>He drives too fast.</li>
                                        </ul>

                                        <p><strong>6. Enough</strong></p>
                                        <p><strong>Usage:</strong> Indicates sufficiency; can be used before or after the word it modifies.</p>
                                        <p><strong>Examples:</strong></p>
                                        <ul>
                                        <li>She isn’t old enough to drive. (adjective + enough)</li>
                                        <li>We have enough chairs for everyone. (enough + noun)</li>
                                        </ul>

                                        <p><strong>7. Therefore</strong></p>
                                        <p><strong>Usage:</strong> Used to indicate a conclusion or result.</p>
                                        <p><strong>Examples:</strong></p>
                                        <ul>
                                        <li>He didn’t study for the test; therefore, he failed.</li>
                                        <li>The project was delayed; therefore, we missed the deadline.</li>
                                        </ul>

                                        <p><strong>8. Hence</strong></p>
                                        <p><strong>Usage:</strong> Similar to "therefore," used to indicate a reason or result (formal).</p>
                                        <p><strong>Examples:</strong></p>
                                        <ul>
                                        <li>The road was blocked; hence, we took a detour.</li>
                                        <li>She was absent, hence the lack of information.</li>
                                        </ul>

                                        <p><strong>9. Since</strong></p>
                                        <p><strong>Usage:</strong> Used to indicate cause or a point in time.</p>
                                        <p><strong>Examples:</strong></p>
                                        <ul>
                                        <li>Since it was raining, we stayed indoors. (cause)</li>
                                        <li>I’ve known her since 2010. (time)</li>
                                        </ul>

                                        <p><strong>10. As</strong></p>
                                        <p><strong>Usage:</strong> Used to indicate cause, manner, or time.</p>
                                        <p><strong>Examples:</strong></p>
                                        <ul>
                                        <li>As it was late, we decided to leave. (cause)</li>
                                        <li>She sang as she worked. (manner)</li>
                                        <li>I met him as I was leaving. (time)</li>
                                        </ul>



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default SoenoughExplanation;