import React from 'react';
import Breadcrumbs from '../../Components/Breadcrumb';
import SEO from '../../Components/SEO';
import GrammarMenu from '../../Components/Menus/GrammarMenu';
import Sidebar from '../../Components/Sidebar';
import Modalscomp from '../../Components/GrammarExerciseComps/modalscomp';


const ModalsObligation = () => {


  return (
    <div>
      <SEO
        title="Grammar Exercises - Modals Obligation"
        description="A big list of English grammar exercises to practice for exams"
        name="English Exam Exercises"
        type="article"
      />
      <Breadcrumbs />
      <div className="grammar-container">
      <Modalscomp />
        <div className="top-margin">
          <GrammarMenu />
          <Sidebar />
        </div>
      </div>
    </div>
  );
};


export default ModalsObligation;

		