import React from 'react';
import Breadcrumbs from '../../Components/Breadcrumb';
import SEO from '../../Components/SEO';
import GrammarMenu from '../../Components/Menus/GrammarMenu';
import Sidebar from '../../Components/Sidebar';
import Simpleprescomp from '../../Components/GrammarExerciseComps/simplepresentexercisescomp.js';
import SimplePresentExplanation from '../../Components/GrammarExplanationComps/simplepresentexplanation.js';

const SimplePresent = () => {
  

  return (
    <div>
      <SEO
        title="Grammar Exercises - Simple Present"
        description="A big list of English grammar exercises to practice for exams"
        name="English Exam Exercises"
        type="article"
      />
      <Breadcrumbs />
      <div className="grammar-container">
        <div><SimplePresentExplanation />
        <Simpleprescomp /></div>
      

          
          
        <div className="top-margin">
          <GrammarMenu />
          <Sidebar />
        </div>
      </div>
    </div>
  );
};


export default SimplePresent;

		